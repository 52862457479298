import { XIcon, AlignJustifyIcon } from 'lucide-react';
import { useState } from 'react';

import { Link } from '@remix-run/react';
import { Button } from '@web/components/ui/button';
import {
  overviewRoute,
  featuresRoute,
  benefitsRoute,
  companyRoute,
  streamerSignInRoute,
  appRoute,
  advertiserWebsite,
  landingPage
} from '@web/utilities/routes';
import { cn } from '@web/utilities/styles';

import { LogoWebsite } from './Logo/LogoWebsite';

export const Navigation = ({
  isAuthenticated = false,
  className = '',
  logoVariant,
  isAdvertiser
}: {
  isAuthenticated?: boolean;
  className?: string;
  isAdvertiser?: boolean;
  logoVariant: 'whiteText' | 'blackText';
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav
      className={cn(
        'fixed inset-x-0 top-0 z-50 flex h-10 items-center justify-between px-4 text-black backdrop-blur-[75px] xl:backdrop-blur-0 xl:h-auto xl:px-24 xl:py-4',
        className
      )}
    >
      <div className="mx-auto flex w-full max-w-[1520px] items-center justify-between">
        <Link
          to={isAdvertiser ? advertiserWebsite() : landingPage()}
          title="Go to homepage"
        >
          <LogoWebsite
            className="h-4 w-[68px] sm:h-6 sm:w-24 xl:h-[31px] xl:w-[131px]"
            variant={logoVariant}
          />
        </Link>

        <button
          onClick={() => setIsOpen(!isOpen)}
          aria-label={isOpen ? 'Close menu' : 'Open menu'}
          className="xl:hidden"
        >
          {isOpen && (
            <XIcon
              className={cn(
                'size-6',
                isAdvertiser ? 'text-white' : 'text-slate-800'
              )}
            />
          )}
          {!isOpen && (
            <AlignJustifyIcon
              className={cn(
                'size-6',
                isAdvertiser ? 'text-white' : 'text-slate-800'
              )}
            />
          )}
        </button>

        <MobileMenu
          isOpen={isOpen}
          isAuthenticated={isAuthenticated}
          isAdvertiser={isAdvertiser}
        />
        <DesktopMenu
          isAuthenticated={isAuthenticated}
          isAdvertiser={isAdvertiser}
        />
      </div>
    </nav>
  );
};

const getRoute = (providedRoute: () => string, isAdvertiser?: boolean) => {
  return isAdvertiser
    ? `${advertiserWebsite()}${providedRoute()}`
    : providedRoute();
};

const JoinButton = ({
  className = '',
  isMobile = false,
  isAuthenticated,
  isAdvertiser
}: {
  className?: string;
  isMobile?: boolean;
  isAuthenticated: boolean;
  isAdvertiser?: boolean;
}) => {
  let linkContent;

  if (isAdvertiser) {
    linkContent = (
      <Link
        to="https://meetings.hubspot.com/bart-cywinski"
        target="_blank"
        rel="noopener noreferrer"
      >
        Book a Call
      </Link>
    );
  } else if (isAuthenticated) {
    linkContent = (
      <Link to={appRoute()} prefetch="intent">
        Go to app
      </Link>
    );
  } else {
    linkContent = (
      <Link to={streamerSignInRoute()} prefetch="intent">
        Join Now
      </Link>
    );
  }

  return (
    <Button
      variant="landing"
      size="default"
      className={cn(
        isMobile ? 'h-[49px] w-[171px]' : 'h-[33px] w-[111px]',
        'gap-2 text-sm font-semibold rounded-md',
        className
      )}
      asChild
    >
      {linkContent}
    </Button>
  );
};

const MobileMenu = ({
  isOpen,
  isAuthenticated,
  isAdvertiser
}: {
  isOpen: boolean;
  isAuthenticated: boolean;
  isAdvertiser?: boolean;
}) => (
  <div
    className={cn(
      `transition-max-height absolute left-0 top-full w-full overflow-hidden bg-white/50 ${
        isAdvertiser ? 'bg-slate-700 text-slate-100' : 'bg-white text-slate-900'
      } duration-500 ease-in-out`,
      isOpen
        ? `max-h-screen border-b ${
            isAdvertiser ? 'border-slate-800' : 'border-slate-400'
          }`
        : 'max-h-0',
      'xl:hidden'
    )}
  >
    <ul className="flex flex-col items-center">
      <li className="mt-4 text-center">
        <Link
          to={getRoute(overviewRoute, isAdvertiser)}
          prefetch="intent"
          className={cn(
            'mb-5 block rounded-md px-14 pb-3 pt-3 font-inter text-base font-light',
            isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
          )}
        >
          Overview
        </Link>
      </li>
      <li className="text-center">
        <Link
          to={getRoute(featuresRoute, isAdvertiser)}
          prefetch="intent"
          className={cn(
            'mb-6 block rounded-md px-14 py-3 font-inter text-base font-light',
            isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
          )}
        >
          Features
        </Link>
      </li>
      <li className="text-center">
        <Link
          to={getRoute(benefitsRoute, isAdvertiser)}
          prefetch="intent"
          className={cn(
            'mb-6 block rounded-md px-14 py-3 font-inter text-base font-light',
            isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
          )}
        >
          Benefits
        </Link>
      </li>
      <li className="text-center">
        <Link
          to={getRoute(companyRoute, isAdvertiser)}
          prefetch="intent"
          className={cn(
            'mb-6 block rounded-md px-14 py-3 font-inter text-base font-light',
            isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
          )}
        >
          Company
        </Link>
      </li>
      <li className="text-center">
        <a
          href="https://blog.onezee.io"
          className={cn(
            'mb-6 block rounded-md px-14 py-3 font-inter text-base font-light',
            isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
          )}
        >
          Blog
        </a>
      </li>
      <li className="mb-10 mt-4">
        <JoinButton
          isAdvertiser={isAdvertiser}
          isMobile
          isAuthenticated={isAuthenticated}
        />
      </li>
    </ul>
  </div>
);

const DesktopMenu = ({
  isAuthenticated,
  isAdvertiser
}: {
  isAuthenticated: boolean;
  isAdvertiser?: boolean;
}) => (
  <div
    className={cn(
      'hidden items-center gap-6 rounded-xl pl-1 py-1.5 pr-4 xl:flex',
      isAdvertiser ? 'bg-slate-700 text-slate-100' : 'bg-white text-slate-900'
    )}
  >
    <div className="flex items-center gap-2">
      <Link
        to={getRoute(overviewRoute, isAdvertiser)}
        prefetch="intent"
        className={cn(
          'px-3 py-2 text-center font-inter text-sm font-normal leading-normal hover:rounded-md',
          isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
        )}
      >
        Overview
      </Link>
      <Link
        to={getRoute(featuresRoute, isAdvertiser)}
        prefetch="intent"
        className={cn(
          'px-4 py-2 text-center font-inter text-sm font-normal leading-normal hover:rounded-md',
          isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
        )}
      >
        Features
      </Link>
      <Link
        to={getRoute(benefitsRoute, isAdvertiser)}
        prefetch="intent"
        className={cn(
          'px-4 py-2 text-center font-inter text-sm font-normal leading-normal hover:rounded-md',
          isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
        )}
      >
        Benefits
      </Link>
      <Link
        to={getRoute(companyRoute, isAdvertiser)}
        prefetch="intent"
        className={cn(
          'px-4 py-2 text-center font-inter text-sm font-normal leading-normal hover:rounded-md',
          isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
        )}
      >
        Company
      </Link>

      <a
        href="https://blog.onezee.io"
        className={cn(
          'px-4 py-2 text-center font-inter text-sm font-normal leading-normal hover:rounded-md',
          isAdvertiser ? 'hover:bg-slate-600' : 'hover:bg-slate-50'
        )}
      >
        Blog
      </a>
    </div>
    <JoinButton isAdvertiser={isAdvertiser} isAuthenticated={isAuthenticated} />
  </div>
);
