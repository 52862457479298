export const appRoute = () => '/app';

export const advertiserRoute = () => `${appRoute()}/advertiser`;

const streamerRoute = () => `${appRoute()}/streamer`;

export const signOutRoute = () => '/sign-out';

export const streamerSignInRoute = () => '/streamer/sign-in';

export const advertiserSignInRoute = () => '/advertiser/sign-in';

export const advertiserProfileRoute = () => `${advertiserRoute()}/profile`;

export const advertiserInvitationsRoute = () =>
  `${advertiserRoute()}/invitations`;

export const advertiserCampaignsRoute = () => `${advertiserRoute()}/campaigns`;

export const adminRoute = () => `${appRoute()}/admin`;

export const adminSignInRoute = () => `/admin/sign-in`;

export const adminSignOutRoute = () => `/admin/sign-out`;

export const adminAllStreamersRoute = () => `${adminRoute()}/streamers`;

export const adminAllAdvertisersRoute = () => `${adminRoute()}/advertisers`;

export const adminAllCampaignsRoute = () => `${adminRoute()}/campaigns`;

export const adminCommunicationRoute = () => `${adminRoute()}/communication`;

export const adminCampaignsDetailsRoute = (campaignId: string) =>
  `${adminAllCampaignsRoute()}/${campaignId}`;

export const adminDraftRoute = (draftId: string) =>
  `${adminRoute()}/drafts/${draftId}`;

export const adminSettingsRoute = () => `${adminRoute()}/settings`;

export const adminStreamerDetailsRoute = (streamerId: string) =>
  `${adminAllStreamersRoute()}/${streamerId}`;

export const adminStreamerCampaignDetailsRoute = (
  streamerId: string,
  campaignId: string
) => `${adminStreamerDetailsRoute(streamerId)}/${campaignId}`;

export const adminPayoutsSettingsRoute = () =>
  `${adminSettingsRoute()}/payouts`;

export const adminAdvertiserDetailsRoute = (advertiserId: string) =>
  `${adminAllAdvertisersRoute()}/${advertiserId}`;

export const adminCampaignDetailsRoute = (campaignId: string) =>
  `${adminAllCampaignsRoute()}/${campaignId}`;

export const adminCampaignClicksAndViewsRoute = (campaignId: string) =>
  `${adminAllCampaignsRoute()}/${campaignId}/clicks-and-views`;

export const adminEmailsTemplatesSettingsRoute = () =>
  `${adminSettingsRoute()}/templates`;

export const adminEmailsTemplateSettingsRoute = (templateId: string) =>
  `${adminEmailsTemplatesSettingsRoute()}/${templateId}`;

export const adminCommunicationStreamersRoute = () =>
  `${adminCommunicationRoute()}/streamers`;

export const adminCommunicationAdvertisersRoute = () =>
  `${adminCommunicationRoute()}/advertisers`;

export const adminCommunicationNewEmailRoute = () =>
  `${adminCommunicationRoute()}/new`;

export const adminCommunicationEditRoute = (emailId: string) =>
  `${adminCommunicationRoute()}/${emailId}/edit`;

export const adminCommunicationDetailsRoute = (emailId: string) =>
  `${adminCommunicationRoute()}/${emailId}`;

export const advertiserCampaignsPaymentCompleteRoute = () =>
  `${advertiserCampaignsRoute()}/payment_complete`;

export const advertiserCampaignRoute = (campaignId: string) =>
  `${advertiserCampaignsRoute()}/${campaignId}`;

export const advertiserDeleteCampaignRoute = (campaignId: string) =>
  `${advertiserCampaignRoute(campaignId)}/delete`;

export const advertiserCancelCampaignRoute = (campaignId: string) =>
  `${advertiserCampaignRoute(campaignId)}/cancel`;

export const advertiserExtendCampaignBudgetRoute = (campaignId: string) =>
  `${advertiserCampaignRoute(campaignId)}/extend-budget`;

export const advertiserCampaignRequestsRoute = (campaignId: string) =>
  `${advertiserCampaignRoute(campaignId)}/requests`;

export const advertiserCampaignJoinersRoute = (campaignId: string) =>
  `${advertiserCampaignRoute(campaignId)}/joiners`;

export const advertiserCampaignReportRoute = (campaignId: string) =>
  `${advertiserCampaignRoute(campaignId)}/report`;

export const advertiserBillingRoute = () => `${advertiserRoute()}/billing`;

export const advertiserInvoicesRoute = () =>
  `${advertiserBillingRoute()}/invoices`;

export const advertiserNotificationsRoute = () =>
  `${advertiserRoute()}/notifications`;

const advertiserOrganizationsRoute = () => `${advertiserRoute()}/organizations`;

export const advertiserSelectOrganizationRoute = () =>
  `${advertiserOrganizationsRoute()}/select`;

export const advertiserNewOrganizationRoute = () =>
  `${advertiserOrganizationsRoute()}/new`;

export const advertiserOrganizationSettingsRoute = () =>
  `${advertiserOrganizationsRoute()}/settings`;

export const advertiserOrganizationMembersRoute = () =>
  `${advertiserOrganizationsRoute()}/members`;

export const advertiserOrganizationInvitationsRoute = () =>
  `${advertiserOrganizationsRoute()}/invitations`;

export const streamerProfileSettingsRoute = () => `${streamerRoute()}/settings`;

export const streamerSetupSettingsRoute = () =>
  `${streamerProfileSettingsRoute()}/setup`;

export const streamerPayoutSettingsRoute = () =>
  `${streamerProfileSettingsRoute()}/payout`;

export const streamerAllCampaignsRoute = () => `${streamerRoute()}/campaigns`;

export const streamerDashboardRoute = () => `${streamerRoute()}/dashboard`;

export const streamerJoinedCampaignsRoute = () =>
  `${streamerAllCampaignsRoute()}/joined`;

export const streamerCampaignRoute = (campaignId: string) =>
  `${streamerAllCampaignsRoute()}/${campaignId}`;

export const streamerInsightsRoute = () => `${streamerRoute()}/insights`;

export const streamerPayoutRoute = () => `${streamerRoute()}/payout`;

export const streamerHelpRoute = () => `${streamerRoute()}/help`;

export const streamerCampaignLinkRoute = (linkId: string, slug: string) =>
  `/l/${linkId}/${slug}`;

export const apiRoute = () => `/api`;

export const stripeRoute = () => `${apiRoute()}/stripe`;

const apiObsRoute = (token: string) => `${apiRoute()}/obs/${token}`;

export const apiObsOverlayRoute = (token: string, overlayDisplayName: string) =>
  `${apiObsRoute(token)}/overlay?layer-name=${overlayDisplayName}`;

export const apiObsCampaignsRoute = (token: string) =>
  `${apiObsRoute(token)}/campaigns`;

export const apiObsNextCampaignRoute = (token: string) =>
  `${apiObsCampaignsRoute(token)}/next`;

export const apiEmailsRoute = () => `/aapi/emails`;

export const apiStreamerCompensateRoute = (streamerId: string) =>
  `/aapi/streamers/${streamerId}/compensate`;

export const overviewRoute = () => '/#overview';

export const featuresRoute = () => '/#features';

export const benefitsRoute = () => '/#benefits';

export const companyRoute = () => '/#company';

export const contactRoute = () => '/contact';

export const landingPage = () => '/';

export const advertiserWebsite = () => '/advertiser';

export const brandForm = () => '/brand-form';

export const apiGenerateCampaignMessageRoute = (campaignId: string) =>
  `/aapi/campaigns/${campaignId}/messages`;

export const tosRoute = () => '/terms-of-service';

export const privacyPolicyRoute = () => '/privacy-policy';
